// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-detail-introduction-detail-js": () => import("/opt/build/repo/src/pages/sections/detail/introduction-detail.js" /* webpackChunkName: "component---src-pages-sections-detail-introduction-detail-js" */),
  "component---src-pages-sections-detail-skills-details-js": () => import("/opt/build/repo/src/pages/sections/detail/skills-details.js" /* webpackChunkName: "component---src-pages-sections-detail-skills-details-js" */),
  "component---src-pages-sections-detail-work-details-js": () => import("/opt/build/repo/src/pages/sections/detail/work-details.js" /* webpackChunkName: "component---src-pages-sections-detail-work-details-js" */),
  "component---src-pages-sections-splash-achievements-splash-js": () => import("/opt/build/repo/src/pages/sections/splash/achievements-splash.js" /* webpackChunkName: "component---src-pages-sections-splash-achievements-splash-js" */),
  "component---src-pages-sections-splash-introduction-splash-js": () => import("/opt/build/repo/src/pages/sections/splash/introduction-splash.js" /* webpackChunkName: "component---src-pages-sections-splash-introduction-splash-js" */),
  "component---src-pages-sections-splash-skills-splash-js": () => import("/opt/build/repo/src/pages/sections/splash/skills-splash.js" /* webpackChunkName: "component---src-pages-sections-splash-skills-splash-js" */),
  "component---src-pages-sections-splash-work-splash-js": () => import("/opt/build/repo/src/pages/sections/splash/work-splash.js" /* webpackChunkName: "component---src-pages-sections-splash-work-splash-js" */)
}

