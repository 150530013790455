import React from "react"

export const ThemeContext = React.createContext()

export class ThemeProvider extends React.Component {
  state = {
    darkTheme: false,
  }

  // LOG IN USER
  setTheme = darkTheme => {
    this.setState({
      darkTheme,
    })
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{
          state: this.state,
          setTheme: this.setTheme,
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    )
  }
}
